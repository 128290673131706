import useToggle from '@pasqal/core/hooks/useToggle';
import PasqalLogo from '@pasqal/core/ui/components/PasqalLogo';
import Button from '@pasqal/core/ui/components/button/Button';
import SettingsDropdown from '@pasqal/core/ui/components/dropdown/SettingsDropdown';
import Stack from '@pasqal/core/ui/components/layout/Stack';
import ChapterNav from '~/components/ChapterNav';
import Container from '~/components/layout/Container';
import { checkIsOnboarded } from '~/utils/auth';
import { navigateTo } from '~/utils/navigateTo';
import TopHeaderDrawerMenu from './TopHeaderDrawerMenu';

import type { DropdownMenuItem } from '@pasqal/core/ui/components';
import type { ApiUser } from '~/@types/api/user';
import type { ChapterNavLink } from '~/components/ChapterNav';

import './topHeader.css';

export interface Props {
  user?: ApiUser;
  currentChapterUid?: string;
  isCurrentChapterDone?: boolean;
  chapterNavLinks?: ChapterNavLink[];
  levelUrl?: string;
  disabledPages?: string[];
  navigate?: (href: string) => void;
  signIn?: () => Promise<Response | void>;
  signOut?: () => Promise<void>;
  signUp?: () => Promise<Response | void>;
}

export const TopHeader = ({
  user,
  currentChapterUid,
  isCurrentChapterDone,
  chapterNavLinks,
  levelUrl,
  disabledPages = [],
  navigate = navigateTo,
  signIn,
  signOut,
  signUp
}: Props) => {
  const {
    isOpen: isMenuOpen,
    handleOpen: handleMenuOpen,
    handleClose: handleMenuClose
  } = useToggle(false);

  const hasChapterNav = currentChapterUid && chapterNavLinks;

  const handleSignInClick = async () => {
    if (signIn) {
      await signIn();
    }
  };

  const handleSignUpClick = async () => {
    if (signUp) {
      await signUp();
    }
  };

  const handleSignOut = async () => {
    if (signOut) {
      await signOut();
    }
  };

  const dropdownItems: DropdownMenuItem[] = [
    {
      label: 'Profile',
      onClick: () => {
        const gotoUrl = '/profile';
        navigate(gotoUrl);
      }
    },
    {
      label: 'Log out',
      onClick: async () => {
        await handleSignOut();
      }
    }
  ];

  return (
    <>
      <header className="TopHeader">
        <div className="TopHeaderMainNav">
          <Container
            className="TopHeaderMainNav-container"
            size="full"
            hasGutters={false}
          >
            <Stack direction="horizontal" alignY="center" isDispatchingX>
              <div className="TopHeaderMainNav-left">
                <a href="/">
                  <PasqalLogo size="md" variant="community" />
                  <span className="u-visuallyHidden">Pasqal Community</span>
                </a>
              </div>
              <div className="TopHeaderMainNav-nav">
                <Stack
                  as="nav"
                  direction="horizontal"
                  spacing="200"
                  alignX="center"
                >
                  <a href="/">Home</a>
                  <a href="/learning">Learning</a>
                  {!disabledPages.includes('access') && (
                    <a href="/access">Access</a>
                  )}
                  <a href="/resources">Resources</a>
                  <a href="/tools">Tools</a>
                </Stack>
              </div>
              <div className="TopHeaderMainNav-right">
                <Stack direction="horizontal" spacing="200" alignX="end">
                  {user ? (
                    checkIsOnboarded(user) ? (
                      <SettingsDropdown
                        navItems={dropdownItems}
                        user={{
                          givenName: user.givenName,
                          familyName: user.familyName
                        }}
                      />
                    ) : (
                      <Button variant="text" size="sm" onClick={handleSignOut}>
                        Log out
                      </Button>
                    )
                  ) : (
                    <>
                      <Button
                        variant="text"
                        size="sm"
                        onClick={handleSignInClick}
                      >
                        Log in
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </Button>
                    </>
                  )}
                </Stack>
              </div>
              <div className="TopHeaderMainNav-menuButton">
                <Button
                  id="top-header-drawer-menu-button"
                  iconOnly="menu"
                  size="md"
                  variant="outlined"
                  onClick={handleMenuOpen}
                >
                  Main navigation menu button
                </Button>
              </div>
            </Stack>
          </Container>
        </div>
        {hasChapterNav && (
          <ChapterNav
            currentChapterUid={currentChapterUid}
            isCurrentChapterDone={isCurrentChapterDone}
            links={chapterNavLinks}
            levelUrl={levelUrl}
            navigate={navigate}
          />
        )}
      </header>
      <TopHeaderDrawerMenu
        isOpen={isMenuOpen}
        user={user}
        disabledPages={disabledPages}
        onClose={handleMenuClose}
        onSignInClick={handleSignInClick}
        onSignUpClick={handleSignUpClick}
        onSignOutClick={handleSignOut}
      />
    </>
  );
};
