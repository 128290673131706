import { getClasses } from '@pasqal/core/helpers/styles';

import type { ComponentWithChildrenAndHTMLAttributes } from '@pasqal/core/ui/components/types';

import './container.css';

export type ContainerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
interface Props extends ComponentWithChildrenAndHTMLAttributes<HTMLDivElement> {
  size?: ContainerSize;
  className?: string;
  hasGutters?: boolean;
}

interface Styles {
  size: Record<ContainerSize, string>;
}

const styles: Styles = {
  size: {
    xs: 'Container--xs',
    sm: 'Container--sm',
    md: 'Container--md',
    lg: 'Container--lg',
    xl: 'Container--xl',
    full: 'Container--full'
  }
};

export const Container = ({
  size = 'md',
  hasGutters = true,
  children,
  className,
  ...rest
}: Props) => {
  const css = getClasses([
    'Container',
    styles.size[size],
    hasGutters && 'Container--withGutters',
    className
  ]);
  return (
    <div className={css} {...rest}>
      {children}
    </div>
  );
};
