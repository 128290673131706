import * as Sentry from '@sentry/astro';
import { useEffect, useRef } from 'react';
import { getClasses } from '@pasqal/core/helpers/styles';
import Tooltip from '@pasqal/core/ui/components/Tooltip';
import Button from '@pasqal/core/ui/components/button/Button';
import Stack from '@pasqal/core/ui/components/layout/Stack';
import { Text } from '@pasqal/core/ui/components/typography/Text';
import { updateProgress } from '~/api/progress';
import Container from '~/components/layout/Container';
import { navigateTo } from '~/utils/navigateTo';

import type { ApiProgress } from '~/@types/api/progress';

import './chapterNav.css';

type ChapterNavLinkStatus = 'ACTIVE' | 'DONE' | 'IN_PROGRESS';

export interface ChapterNavLink {
  label: string;
  url: string;
  status: ChapterNavLinkStatus;
}

interface Props {
  currentChapterUid?: string;
  isCurrentChapterDone?: boolean;
  links: ChapterNavLink[];
  levelUrl?: string;
  navigate?: (href: string) => void;
}

interface LinkStyles {
  status: Record<ChapterNavLinkStatus, string>;
}

const itemStyles: LinkStyles = {
  status: {
    ACTIVE: 'ChapterNavLink--active',
    DONE: 'ChapterNavLink--done',
    IN_PROGRESS: 'ChapterNavLink--inProgress'
  }
};

export const ChapterNav = ({
  currentChapterUid,
  isCurrentChapterDone,
  links,
  levelUrl,
  navigate = navigateTo
}: Props) => {
  const linksRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (gotoUrl: string) => async () => {
    try {
      if (currentChapterUid && !isCurrentChapterDone) {
        const payload: ApiProgress = {
          [currentChapterUid]: 'DONE'
        };
        await updateProgress(payload);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      navigate(gotoUrl);
    }
  };

  useEffect(() => {
    if (linksRef.current) {
      const activeLinkIndex = Math.max(
        0,
        links.findIndex(({ status }) => status === 'ACTIVE')
      );
      // Scroll to the active link
      linksRef.current.scrollLeft =
        (
          linksRef.current.firstChild?.childNodes[
            activeLinkIndex
          ] as HTMLLIElement
        ).offsetLeft || 0;
    }
  }, [links]);

  return (
    <nav className="ChapterNav">
      <Container className="ChapterNav-container" size="lg" hasGutters={false}>
        <Stack direction="horizontal" spacing="75" alignY="center">
          {levelUrl && (
            <div className="ChapterNav-levelButton">
              <Tooltip content="Back to floor" placement="right" isTiny>
                <Button
                  role="link"
                  iconOnly="world"
                  size="md"
                  variant="text"
                  onClick={handleClick(levelUrl)}
                />
              </Tooltip>
            </div>
          )}
          <div className="ChapterNav-links" ref={linksRef}>
            <Stack
              as="ul"
              spacing="200"
              direction="horizontal"
              alignY="center"
              isDispatchingX
            >
              {links.map(({ label, url, status }, i) => {
                const css = getClasses([
                  'ChapterNavLink',
                  itemStyles.status[status]
                ]);
                return (
                  <li key={`item-${i}`} className={css}>
                    <Text
                      as="button"
                      role="link"
                      onClick={handleClick(url)}
                      variant="tiny"
                      align="center"
                    >
                      {label}
                    </Text>
                  </li>
                );
              })}
            </Stack>
          </div>
        </Stack>
      </Container>
    </nav>
  );
};
