// This component allows for a separation of concerns by making the TopHeader component
// mostly presentational for easier usage with Storybook and independent from Astro packages.

import { navigate } from 'astro:transitions/client';
import { signIn, signOut, signUp } from '~/utils/auth';
import { TopHeader } from './TopHeader';

import type { Props as ITopHeaderProps } from './TopHeader';

export const TopHeaderContainer = (props: ITopHeaderProps) => {
  return (
    <TopHeader
      {...props}
      navigate={navigate}
      signIn={signIn}
      signOut={signOut}
      signUp={signUp}
    />
  );
};
